<template>
  <b-card>
    <h1>Bienvenue dans votre interface Agriprogress 🙌</h1>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";

export default {
  components: {
    BCard
  }
};
</script>
